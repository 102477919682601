<template>
    <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
      <el-form :model="formData" :rules="rules" ref="formRef" class="w-100">
        <div class="text-center mb-10">
          <h1 class="text-dark mb-3">Change Password</h1>
        </div>
        <div class="fv-row">
          <label class="form-label fw-bolder text-gray-900 fs-6">Email</label>
          <el-form-item prop="email">
          <el-input size="large" v-model="formData.email" type="text" placeholder="email" disabled />
          </el-form-item>
        </div>
        <div class="fv-row">
          <label class="form-label fw-bolder text-gray-900 fs-6">Password</label>
          <el-form-item prop="password">
          <el-input size="large" v-model="formData.password" type="password" placeholder="Password"/>
          </el-form-item>
        </div>
        <div class="fv-row mb-10">
          <label class="form-label fw-bolder text-gray-900 fs-6">Confirm Password</label>
          <el-form-item prop="password_confirmation">
            <el-input size="large" v-model="formData.password_confirmation" type="password" placeholder="Confirm Password"/>
          </el-form-item>
        </div>
        <div class="d-flex flex-wrap justify-content-center pb-lg-0">
          <button type="button" class=" btn btn-primary me-2" :disabled="loading" @click="submitForm(formRef, 'save')">{{constants.general.SAVE}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </button>
          <button type="button" class=" btn btn-secondary me-2" @click.prevent="$router.push({ name: 'sign-in'})">{{constants.general.CANCEL}}</button>
        </div>
      </el-form>
    </div>
</template>
<script>
import { reactive, ref, onMounted, onUpdated } from 'vue';
import ApiService from "@/core/services/ApiService";
import { useRoute,useRouter } from 'vue-router'
import { notificationFire } from "@/composable/notification.js";
import globalConstant from "@/core/data/globalConstant.js";
import { getIllustrationsPath } from "@/core/helpers/assets";
export default {
  name: "change-password",
  setup() {
    const formRef = ref(null);
    const loading = ref(false);
    const route = useRoute();
    const router = useRouter();
    const constants = globalConstant;
    const formLoading = ref(false);
    const formData = reactive({
      email: '',
      password: '',
      password_confirmation: '',
      token: route.params.id
    });

    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter the password"));
      } else if (value.length <= 7) {
        callback(new Error("Please enter minimum 8 character password"));
      } else {
        if (formData.password !== '') {
          if (!formRef.value) return
          formRef.value.validateField('password', () => null)
        }
        callback()
      }
    }

    const validateConfirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter confirm password'))
      } else if (value !== formData.password) {
        callback(new Error("Password and confirm password do not match"))
      } else {
        callback()
      }
    } 

    const rules = ref({
      password: [
        {
          validator: validatePassword,
          trigger: "change",
        },
      ],
      password_confirmation: [
        {
          validator: validateConfirmPassword,
          trigger: "change",
        },
      ],      
    });

    const submitForm = async (formEl, btnName) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          ApiService.post('reset-password', formData)
          .then(({ data }) => {
            if(data.status){
              notificationFire(data.message, "success");
              router.push({ name: 'sign-in'});
            }else{
              notificationFire(data.message, "warning");
              router.push({ name: 'sign-in'});
            }
          })
          .catch((error) => {
            let response = error.response;
            if (response) {
              let status = response.status;
              let message = response.data.message;
              notificationFire(message, "error");
              if (status == 404) {
                router.push({ name: 'sign-in'})
              }
            }
          });
        }
      })
    }
    function getUserDataByToken() {
      formLoading.value = true;
      ApiService.post("get-reset-password", {'token' : route.params.id})
      .then(({ data }) => {
        if(data.data){
          formData.email = data.data.email;
        }else{
          notificationFire(data.message, "warning");
          router.push({ name: 'sign-in'});
        }
        formLoading.value = false;
      })
      .catch((error) => {
        formLoading.value = false;
        let response = error.response;
        if (response) {
          let status = response.status;
          let message = response.data.message;
          notificationFire(message, "error");
          if (status == 404) {
            router.push({ name: 'sign-in'})
          }
        }
      });
    }
    //Set breadcrumbs
    onMounted(async () => {
      await getUserDataByToken();
    })
    return {
      formData,
      rules,
      submitForm,
      formRef,
      loading,
      constants,
      formLoading,
      getIllustrationsPath
    };
  },
};
</script>